import React from "react";
import { State } from "./index.interface";
import Header from "../../components/Header";

import styles from "./index.module.less";
class Register extends React.Component<PageProps, State> {
  constructor(props: PageProps) {
    super(props);
    this.state = { title: '' };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.title = "3Dbody患者教育平台";

  }

  componentDidUpdate(prevProps: any) {
  }
  render() {
    const { } = this.state;
    const { history } = this.props;
    return (
      <div className={styles.container}>
        <Header type={0} history={history}></Header>
      </div>)

  }
}

export default Register;
