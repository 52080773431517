import { axios_json } from "./axios";

// 获取详情
export const getDetail = (code: string) => {
  return axios_json({
    url: `/api/edu/patient/program/detail/${code}`,
  });
};
export const getText = (code: string) => {
  return axios_json({
    url: code,
  });
};
export const bindUser = (data: { code: string, mobile: string, ownerUserId: string }) => {
  return axios_json({
    url: `/api/edu/patient/account/bind`,
    method: "post",
    data,
  });
};

// 获取话题详情
export const getTopicDetail = (topicId: number) => {
  return axios_json({
    url: "/api/topic/detail/" + topicId,
  });
};

// 获取圈子列表
export const getCircleList = () => {
  return axios_json({
    url: "/api/circle/list",
  });
};
// 获取圈子详情
export const getCircleDetail = (id: number) => {
  return axios_json({
    url: "/api/circle/detail/" + id,
  });
};
// 获取某个圈子话题列表
export const getCircleTopicList = (data: {
  circleId: number;
  orderType: number;
  page: number;
  size: number;
}) => {
  return axios_json({
    url: "/api/circle/topic/list",
    data,
  });
};

// 加入、取消圈子
export const joinCircle = (data: { id: number }) => {
  return axios_json({
    url: "/api/u/join",
    method: "post",
    data,
  });
};
// 获取培训列表
export const getTrainList = (data: { page: number; size: number }) => {
  return axios_json({
    url: "/api/train/list",
    data,
  });
};
// 获取直播列表
export const getLiveList = (data: {
  page: number;
  size: number;
  status: number;
}) => {
  return axios_json({
    url: "/api/live/list",
    data,
  });
};
