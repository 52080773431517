import React from "react";
import { State } from "./index.interface";
import { NavBar, Icon, Button } from "antd-mobile";
import { getIOS } from "../../utils/common";
import logo from "../../assets/img/logo.png";

import styles from "./index.module.less";
class Header extends React.Component<PageProps, State> {
	constructor(props: PageProps) {
		super(props);
		this.state = {};
	}

	goBackPage = () => {
		const { history, type } = this.props;
		if (type != 0) {
			history.go(-1); //返回上一页这段代码

			//window.history.back()
		}
	};
	onDownLoad = () => {
		let giIOS = getIOS();
		console.log(giIOS);
		if (giIOS) {
			window.location.href = "https://itunes.apple.com/cn/app/id1003630908?mt=8";
		} else {
			window.location.href =
				"https://a.app.qq.com/o/simple.jsp?pkgname=com.HaoTuBang.H3DBodyFree&channel=0002160650432d595942&fromcase=60001&from=singlemessage#";
		}
	};
	render() {
		const { type, history } = this.props;

		return (
			<NavBar
				mode="light"
				className={styles.nav}
				icon={
					type == 0 ? (
						<img src={logo} className={styles.navLogo} />
					) : (
						<Icon type="left" className={styles.navIcon} />
					)
				}
				leftContent={
					type == 0 ? (
						<div>
							<span className={styles.navText}>3Dbody患者教育平台</span>
						</div>
					) : (
						<div>
							<span className={styles.navText1} onClick={() => history.go(-1)}>
								返回
							</span>

						</div>
					)
				}
				rightContent={[
					<Button type="primary" key="0" inline size="small" className={styles.btn} onClick={this.onDownLoad}>
						APP下载
					</Button>
				]}
			/>
		);
		// );
	}
}

export default Header;
