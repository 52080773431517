import React from "react";
import { State } from "./index.interface";
//import { img_url } from "../../assets/js/global";
import styles from "./index.module.less";
import qs from "qs";
import Header from "../../components/Header";

class News extends React.Component<PageProps, State> {
	constructor(props: PageProps) {
		super(props);
		this.state = {
			url: ''
		};
	}

	componentDidMount() {
		//window.scrollTo(0, 0);
		window.addEventListener('resize', this.handleResize.bind(this)) //监听窗口大小改变
		let width = document.body.clientWidth
		this.handleClientW(width);
	}
	handleResize = (e: any) => {
		let e_width = e.target.innerWidth;
		this.handleClientW(e_width);
	}
	handleClientW = (width: any) => {
		const params = qs.parse(location.search, { ignoreQueryPrefix: true });
		let url = params.path
		if (!url) { return }
		if (
			url.indexOf(".jpg") >= 0 ||
			url.indexOf(".png") >= 0 ||
			url.indexOf(".webp") >= 0 ||
			url.indexOf(".gif") >= 0
		) {
			url += '?imageView2/2/w/' + width + '/format/jpg';
		}

		this.setState({ url: url });
		var newFrame = document.createElement("iframe");
		newFrame.src = url;
		var parent = document.getElementById('parentDom')
		if (parent) {
			parent.innerHTML = '';
			parent.appendChild(newFrame)
		}
	}
	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize.bind(this));
	}
	render() {
		const { } = this.state;
		return (
			<div className={styles.container}>
				<Header type={1} history={history}></Header>
				<div className={styles.iframeBox} id="parentDom">
				</div>
			</div>

		);
		// );
	}
}

export default News;
