import React from "react";
import { Icon, Flex, Result } from "antd-mobile";
import { State } from "./index.interface";
//import { img_url } from "../../assets/js/global";
import styles from "./index.module.less";
//import ErcodeItem from "../../components/ErcodeItem";
import qs from "qs";
import { getDetail, getText } from "../../api/server";
//import Header from "../../components/Header";
import Panzoom from "panzoom";
import { formatTime } from "../../utils/common";
//import { isBuffer } from "util";
import ReactPlayer from "react-player";

class News extends React.Component<PageProps, State> {
  constructor(props: PageProps) {
    super(props);
    this.state = {
      code: "",
      activeTab: 0,
      detail: {
        title: "",
        pointList: [],
        introduction: "",
        code: "",
        startTime: "",
        endTime: "",
        action: [],
        video: [],
        questionnaires: [],
        isFree: true,
      },
      model: false,
      source: {
        name: "",
        status: "",
        uid: "",
        url: "",
        type: "",
      },
      show: false,
      itemDetail: {},
    };
  }

  componentDidMount() {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });
    this.setState(
      {
        code: params.code + "",
      },
      () => {
        this.getDataDetail(params.code);
      }
    );
  }
  handleToSoftDetail = async (item: any, itemDetail: any) => {
    //	const { history } = this.props;
    //history.push(`/ercode_view_iframe?path=${item.file ? item.file : item.url}&name=${item.name ? item.name : item.title}`);
    let url = item.url;
    let type = "";
    if (!url) return;
    if (url.indexOf(".mp4") >= 0) {
      type = "video";
    } else if (
      url.indexOf(".jpg") >= 0 ||
      url.indexOf(".png") >= 0 ||
      url.indexOf(".webp") >= 0 ||
      url.indexOf(".gif") >= 0
    ) {
      type = "img";
    } else if (
      url.indexOf(".doc") >= 0 ||
      url.indexOf(".docx") >= 0 ||
      url.indexOf(".webp") >= 0 ||
      url.indexOf(".xls") >= 0 ||
      url.indexOf(".xlsx") >= 0 ||
      url.indexOf(".ppt") >= 0 ||
      url.indexOf(".pptx") >= 0
    ) {
      type = "office";
    } else if (url.indexOf(".txt") >= 0) {
      type = "text";
    } else {
      type = "url";
    }
    item.type = type;
    item.file = item.file ? item.file : item.url;
    item.title = item.title ? item.title : item.name;
    if (item.file.indexOf("http") == -1) {
      item.file = "https://www.3dbody.com" + item.file;
    }

    if (item.type == "office") {
      location.href =
        "https://view.officeapps.live.com/op/view.aspx?src=" + item.file;

      return;
    }
    if (item.type == "text") {
      let res = await getText(item.file);

      item.content = JSON.stringify(res);
    }

    this.setState(
      {
        model: true,
        source: item,
        itemDetail: itemDetail,
      },
      () => {
        const elem = document.getElementById("img");
        if (elem) {
          Panzoom(elem, {
            maxZoom: 2,
            smoothScroll: false,
            minZoom: 0.5,
            zoomSpeed: 0.065,
            pinchSpeed: 2, // 比手指之间的距离快两倍,
            //transformOrigin: { x: 0.5, y: 0.5 },
          });
        }
      }
    );
  };
  getDataDetail = async (code: string) => {
    let res: any = {};
    res = await getDetail(code);
    if (res.code === "OK") {
      res.data.action = [];
      res.data.video = [];
      for (var i = 0; i < res.data.pointList.length; i++) {
        res.data.pointList[i].image = res.data.pointList[i].image
          ? JSON.parse(res.data.pointList[i].image)
          : "";
        res.data.pointList[i].file = res.data.pointList[i].file
          ? JSON.parse(res.data.pointList[i].file)
          : [];
        if (res.data.pointList[i].type == "1") {
          res.data.action.push(res.data.pointList[i]);
        } else {
          res.data.video.push(res.data.pointList[i]);
        }
      }

      this.setState({
        detail: res.data,
        show: true,
      });
    } else {
      this.setState({
        show: false,
      });
    }
  };

  handleback = () => {
    this.setState({ model: false });
  };

  render() {
    const { detail, model, source, show, itemDetail } = this.state;
    /* const geturl = (file: any, item: any) => {
			if (!file.url) return ''
			if (file.url.indexOf(".mp4") >= 0) {
				return file.url + "?vframe/jpg/offset/5/w/400/h/400";
			} else if (
				file.url.indexOf(".jpg") >= 0 ||
				file.url.indexOf(".png") >= 0 ||
				file.url.indexOf(".webp") >= 0 ||
				file.url.indexOf(".gif") >= 0
			) {
				return file.url;
			} else {
				return item.image.file;
			}
		} */

    return (
      <div
        className={styles.container}
        style={{ overflow: model ? "hidden" : "auto" }}
      >
        {/* <Header type={0} history={history}></Header> */}

        {show && detail.isFree == true ? (
          <div className={styles.main}>
            <div className={styles.title}>患教处方</div>
            <div className={styles.titleBox}>
              <div className={styles.titlleft}>处方编号： </div>
              <div className={styles.titleright}>{detail.code}</div>
            </div>

            <div className={styles.titleBox}>
              <div className={styles.titlleft}>标题/介绍：</div>
              <div className={styles.titleright}>{detail.title} </div>
            </div>
            <div className={styles.titleBox}>
              <div className={styles.titlleft}>开始~结束时间：</div>
              <div className={styles.titleright}>
                {" "}
                {detail.startTime && detail.endTime
                  ? formatTime(detail.startTime, "YYYY-MM-DD") +
                    " ~ " +
                    formatTime(detail.endTime, "YYYY-MM-DD")
                  : "--"}{" "}
              </div>
            </div>
            <div className={styles.titleBox} style={{ paddingBottom: "10px" }}>
              <div className={styles.titlleft}>处方内容：</div>
              <div className={styles.titleright}> </div>
            </div>
            {detail.action.length > 0 && (
              <div className={styles.ercodeBox}>
                <div className={styles.subtitle}>动作库：</div>
                <Flex wrap="wrap" justify="center">
                  {detail.action &&
                    detail.action.map(
                      (item, index) =>
                        item.file &&
                        item.file.map((fileItem, number) => (
                          <div className={styles.ercodeItem} key={fileItem.url}>
                            <div className={styles.card}>
                              <div
                                key={fileItem.url}
                                onClick={() =>
                                  this.handleToSoftDetail(fileItem, item)
                                }
                                className={styles.item}
                                style={{
                                  position: "relative",
                                  paddingTop: "100%",
                                }}
                              >
                                <img
                                  src={
                                    item.image.file
                                      ? item.image.file
                                      : item.image.url
                                  }
                                  style={{
                                    position: "absolute",
                                    top: "0",
                                    left: "0",
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                    transform: item.isMirror
                                      ? "rotateY(180deg)"
                                      : "rotateY(0deg)",
                                  }}
                                  alt=""
                                />
                                <span
                                  style={{
                                    position: "absolute",
                                    top: "-5px",
                                    left: "-5px",
                                    background: "#ffdd00",
                                    padding: "3px 8px",
                                    borderRadius: "5px",
                                    fontSize: "12px",
                                    display: item.isMirror ? "unset" : "none",
                                  }}
                                >
                                  镜像
                                </span>
                              </div>
                              <div className={styles.ititle}>
                                {item.title}
                                {item.isMirror ? "(镜像)" : ""}
                                {item.file.length > 1 ? "-" + (number + 1) : ""}
                              </div>
                            </div>
                          </div>
                        ))
                    )}
                </Flex>
              </div>
            )}
            {detail.video.length > 0 && (
              <div className={styles.ercodeBox}>
                <div className={styles.subtitle}>患者教育：</div>
                <Flex wrap="wrap" justify="center">
                  {detail.video &&
                    detail.video.map(
                      (item, index) =>
                        item.file &&
                        item.file.map(fileItem => (
                          <div className={styles.ercodeItem} key={fileItem.url}>
                            <div className={styles.card}>
                              <div
                                key={fileItem.url}
                                onClick={() =>
                                  this.handleToSoftDetail(fileItem, item)
                                }
                                className={styles.item}
                                style={{
                                  backgroundImage:
                                    "url(" +
                                    (item.image.file
                                      ? item.image.file
                                      : item.image.url) +
                                    ")",
                                  backgroundRepeat: "no-repeat",
                                  backgroundPosition: "center center",
                                  backgroundSize: "cover",
                                  width: "100%",
                                  paddingTop: "100%",
                                  display: "inline-block",
                                  borderBottom: "1px solid #eee",
                                  position: "relative",
                                  margin: "0px",
                                }}
                              ></div>
                              <div className={styles.ititle}>{item.title}</div>
                            </div>
                          </div>
                        ))
                    )}
                </Flex>
              </div>
            )}

            {/* <div className={styles.ercodeBox} v-if={detail.questionnaires.length}>
	<div className={styles.subtitle}>问卷：</div>
	<Flex wrap="wrap">
		{detail.questionnaires && detail.questionnaires.map((item, index) => (


			<div className={styles.ercodeItem} key={index}>
				<Card className={styles.card}>

					<Card.Header
						title={item.questionnaireTitle}
					/>
				</Card>
			</div>

		))}
	</Flex>
</div> */}
          </div>
        ) : detail.isFree == false ? (
          <Result
            img={
              <Icon
                type="cross-circle-o"
                className="spe"
                style={{ fill: "#F13642" }}
              />
            }
            title="预览失败"
            message="此处方为付费服务，请下载APP购买后方可查看！"
          />
        ) : show == true ? (
          <Result
            img={
              <Icon
                type="cross-circle-o"
                className="spe"
                style={{ fill: "#F13642" }}
              />
            }
            title="预览失败"
            message="此处方已失效，请联系您的患教医生！"
          />
        ) : (
          ""
        )}

        {model ? (
          <div className={styles.modelBox}>
            <div className={styles.topBack}>
              <div
                style={{
                  width: "fit-content",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  whiteSpace: "nowrap",
                  fontSize: "17px",
                  fontWeight: "bold",
                }}
                onClick={() => this.handleback()}
              >
                <Icon type="left" size="lg" />
                返回
              </div>{" "}
              <div className={styles.ititle}>
                {itemDetail.title}
                {itemDetail.isMirror ? "(镜像)" : ""}
              </div>
            </div>

            <div className={styles.sourceBox}>
              {source.type == "video" ? (
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      width: "100%",
                      paddingTop: "56.25%",
                      position: "relative",
                      marginTop: "0px",
                    }}
                  >
                    <ReactPlayer
                      className={
                        "react-player " +
                        (itemDetail.isMirror ? styles.ismirro : "")
                      }
                      style={{
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        top: 0,
                      }}
                      //这里是由上级页面传过来的视频地址
                      url={source.file}
                      playing={true}
                      width="100%"
                      height="100%"
                      controls
                      controlsList="nodownload"
                      loop
                      pip
                      disablePictureInPicture
                      webkit-playsinline="true"
                      x5-video-player-type="h5-page"
                      playsinline={true}
                    />
                  </div>

                  {/* 								<video src={source.file} style={{ width: '100%', marginTop: '45px' }} controls autoPlay controlsList="nodownload" disablePictureInPicture webkit-playsinline playsinline x5-video-player-type x5-playsinline="h5-page"></video>
                   */}
                </div>
              ) : (
                ""
              )}
              {source.type == "img" ? (
                <div className={styles.imgbox}>
                  <div
                    id="img"
                    style={{ width: "100%", height: "100%" }}
                    className={itemDetail.isMirror ? styles.ismirro : ""}
                  >
                    <img src={source.file}></img>
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* 	{source.type == 'office' ? (

							<iframe src={'https://view.officeapps.live.com/op/view.aspx?src=' + source.file}  ></iframe>
						) : ''} */}
              {source.type == "text" ? (
                <div className={styles.text}>{source.content}</div>
              ) : (
                ""
              )}
              {source.type == "url" ? <iframe src={source.file}></iframe> : ""}

              {itemDetail.frequency.length > 0 && (
                <div className={styles.timeBox}>
                  <div className={styles.timeTitle}>锻炼时间：</div>
                  <div className={styles.time}>
                    {itemDetail.frequency.map(
                      (ii: any) =>
                        ii.isSelect && (
                          <div key={ii.id} className={styles.timeItem}>
                            {ii.title}：{ii.count}
                          </div>
                        )
                    )}
                  </div>
                </div>
              )}
              {itemDetail.introduction != "" && (
                <div className={styles.timeBox}>
                  <div className={styles.timeTitle}>介绍：</div>
                  <div className={styles.time}>
                    <div
                      className={styles.introduction}
                      dangerouslySetInnerHTML={{
                        __html: itemDetail.introduction.replace(/\n/g, "<br/>"),
                      }}
                    ></div>
                  </div>
                </div>
              )}
              {itemDetail.note != "" && (
                <div className={styles.timeBox}>
                  <div className={styles.timeTitle}>备注：</div>
                  <div className={styles.time}>
                    <div className={styles.introduction}>{itemDetail.note}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
    // );
  }
}

export default News;
