import React from "react";
import { Result, Icon } from "antd-mobile";
import { State } from "./index.interface";
//import { img_url } from "../../assets/js/global";
import styles from "./index.module.less";
import qs from "qs";
import { bindUser } from "../../api/server";
import Header from "../../components/Header";
import selected_icon from "../../assets/img/selected_icon.png";

class News extends React.Component<PageProps, State> {
	constructor(props: PageProps) {
		super(props);
		this.state = {
			code: '',
			mobile: '',
			userId: '',
			result: '0'
		};
	}

	componentDidMount() {
		//window.scrollTo(0, 0);
		const params = qs.parse(location.search, { ignoreQueryPrefix: true });
		this.setState(
			{
				code: params.code + '', mobile: params.mobile + '', userId: params.userId + '',
			},
			() => {
				if (this.state.code && this.state.mobile && this.state.userId) {
					this.bindUser();
				} else {

					this.setState({ result: '2' });
				}
			}
		);





	}

	bindUser = async () => {
		const { code, mobile, userId } = this.state;
		let res: any = {}
		res = await bindUser({ code: code, mobile: mobile, ownerUserId: userId });
		if (res.code === "OK") {
			this.setState({ result: '1' });
		} else {
			this.setState({ result: '2' });
		}

	};


	render() {
		const { result } = this.state;
		const myImg = (src: any) => <img src={selected_icon} className={styles.resultIcon} alt="" />;
		return (
			<div className={styles.container}>
				<Header type={0} history={history}></Header>
				<div className={styles.main}>
					{result === '1' ? <Result
						img={myImg('')}
						title="绑定成功"
						message={<div>您将收到来自医生给你推荐的患教内容，请点击右上角按钮下载APP，以便您可以更好的查收您定制化的患教处方内容！</div>}
					/> : ''}
					{result === '2' ? <Result
						img={<Icon type="cross-circle-o" className={styles.resultIcon} style={{ fill: '#F13642', }} />}
						title="绑定失败"
						message={<div>绑定失败，请联系您的康复医生！</div>}
					/> : ''}




				</div>





			</div>
		);
		// );
	}
}

export default News;
