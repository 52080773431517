import React from "react";
// import qs from "qs";
import { Switch, Route } from "react-router-dom";
import Home from "./Home";
import ErcodeView from "./ErcodeView";
import ErcodeViewIFrame from "./ErcodeViewIFrame";
import BindUser from "./BindUser";

class Routes extends React.Component<PageProps> {

  render() {
    return (
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/ercode_view" component={ErcodeView} />
        <Route path="/ercode_view_iframe" component={ErcodeViewIFrame} />
        <Route path="/bind-user" component={BindUser} />
      </Switch>
    );
  }
}

export default Routes;
