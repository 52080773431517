import qs from "qs";
import moment from "moment";

// 获取url参数
export const getParmas = (search: string): object => {
  const params = qs.parse(search, { ignoreQueryPrefix: true });
  return params;
};

// 时间段序列化
export const formatDuration = (time: number) => {
  const hours = moment.duration(time * 1000).hours();
  const minutes = moment.duration(time * 1000).minutes();
  const seconds = moment.duration(time * 1000).seconds();

  return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes
    }:${seconds < 10 ? `0${seconds}` : seconds}`;
};
export const formatTime = function (str: any, partten = 'YYYY-MM-DD HH:mm:ss') {
  return moment(str).format(partten)
}
export const wechatPay = (
  data: any,
  successCall = () => { },
  failCall = () => { }
) => {
  // data为后台返参数据
  function onBridgeReady(data: any) {
    window.WeixinJSBridge.invoke(
      "getBrandWCPayRequest",
      {
        appId: data.appId, // 公众号的唯一标识
        timeStamp: data.timeStamp, // 生成签名的时间戳
        nonceStr: data.nonceStr, // 生成签名的随机串
        package: data.packageValue, // 统一支付接口返回的prepay_id参数值
        signType: data.signType, // 签名方式
        paySign: data.paySign, // 支付签名
      },
      function (response: any) {
        if (
          response.err_msg === "get_brand_wcpay_request：ok" ||
          response.err_msg.search("ok") !== -1
        ) {
          // 支付成功的回调
          successCall();
        } else {
          // 支付失败的回调
          failCall();
        }
      }
    );
  }

  if (typeof window.WeixinJSBridge === "undefined") {
    if (document.addEventListener) {
      document.addEventListener("WeixinJSBridgeReady", onBridgeReady, false);
    } else if (document.attachEvent) {
      document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
      document.attachEvent("onWeixinJSBridgeReady", onBridgeReady);
    }
  } else {
    onBridgeReady(data);
  }
};
export const getIOS = () => {
  var u = navigator.userAgent;
  var isXiaomi = u.indexOf("XiaoMi") > -1; // 小米手机
  var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; // 其它安卓
  var isIOS = Boolean(u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)); // ios
  var IOS = false;
  if (isAndroid) {
    //alert("Android系统");
  } else if (isIOS) {
    if (isXiaomi) {
      //  alert(
      // "您的浏览器标识为iPhone，请前往设置：“设置”->“高级设置”->“浏览器标识=默认”"
      // );
    } else {
      // alert("ios系统");
      IOS = true;
    }
  }
  return IOS;
};
export const getispc = () => {
  var ispc = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );

  //console.log(ispc);
  return ispc ? false : true;
};

export const IsPC = () => {
  var userAgentInfo = navigator.userAgent;
  console.log(userAgentInfo);
  var Agents = [
    "Android",
    "iPhone",
    "SymbianOS",
    "Windows Phone",
    "iPad",
    "iPod",
  ];
  var flag = true;
  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false;
      break;
    }
  }
  return flag;
};
